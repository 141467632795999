import './blogDetails.css'
import { React, useLocation, Locale, useEffect, useParams, useState } from '../../libraries';
import { EmptyComponent, Footer, PageNotFound } from '../../common';
import { NavbarLogic } from '../../components';
import { MetaTag } from "../../customHooks/metaKeywords";
import { BreadCrumb } from '../BreadCrumb/BreadCrumb.js';
import { getFormatedDateMonthName } from '../../customHooks/dateTimeFormat.js';
import { decryptData } from '../../customHooks/urlEncyption.js';
import { handleErrorResponse } from '../../services/base.js';
import { getBlog } from '../../services/blog.js';
import DOMPurify from 'dompurify';

// Function to sanitize HTML content
const BlogDetail = () => {
  const sanitizeHtml = (html) => {
   return DOMPurify.sanitize(html);
  };
  const { t } = Locale(); // Assuming this is your localization function
  const params = useParams(); // To get the route params
  const location = useLocation(); // To track route changes
  const [blog, setBlog] = useState(null); // State to hold the blog details
  const [pageNotFound, setPageNotFound] = useState(false); // State for 404 error
  const language = localStorage.getItem("i18nextLng"); // Fetch language from localStorage
  const isThaiLanguage = language === 'thai'; // Check if the language is Thai

  // Breadcrumb data
  const BreadCrumbData = [
    {
      breadCrumbName: t('field.blog'),
      breadCrumbPath: '/blog'
    },
    {
      breadCrumbName: t('field.blog'),
      breadCrumbPath: '/blog'
    }
  ];

  // Cleanup 'isSection' from localStorage when route changes
  useEffect(() => {
    if (localStorage.getItem('isSection')) {
      localStorage.removeItem('isSection');
    }
  }, [location.pathname]);

  // Fetch blog details when the params (specifically encryptedId) change
  useEffect(() => {
    const fetchData = async () => {
      if (params?.encryptedId) {
        try {
          let tempId = await decryptIdFun(params?.encryptedId);
          await fetchBlogDetails(tempId);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();

    // No need for a cleanup function here unless you add subscriptions, etc.
  }, [params]);

  // Function to decrypt the blog ID
  const decryptIdFun = async (encryptedId) => {
    try {
      const decryptedId = await decryptData(encryptedId);
      setPageNotFound(false); // Reset page not found state
      return decryptedId;
    } catch (err) {
      setPageNotFound(true); // Set page not found in case of error
      console.error("Decryption failed:", err);
    }
  };

  // Fetch blog details based on decrypted blog ID
  const fetchBlogDetails = async (blog_id) => {
    try {
      const response = await getBlog();
      const blogsArray = response?.data?.blogs;

      if (blogsArray?.length) {
        const blog = blogsArray.find((obj) => obj.id === blog_id);
        if (blog) {
          setBlog(blog); // Set the blog if found
          setPageNotFound(false);
        } else {
          setPageNotFound(true); // Blog not found
        }
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  // Function to get the blog title based on the selected language
  const getBlogTitle = (blog) => {
    const title = isThaiLanguage ? blog?.title_thai ?? blog?.title_en : blog?.title_en;
    return title ? title.charAt(0).toUpperCase() + title.slice(1) : '';
  };

  // Function to get the blog description based on the selected language
  const getBlogDesc = (blog) => {
    return isThaiLanguage ? blog?.description_thai ?? blog?.description_en : blog?.description_en;
  };

  // Function to remove HTML tags from the blog description
  const removeHtmlTags = (input) => {
    if (!input) return ""; // Return an empty string if input is falsy

    // Remove HTML tags using DOMParser
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      {(pageNotFound) ? <>
        <PageNotFound />
      </> : <>

    
      <MetaTag custom_title={blog?.meta_title} custom_description={blog?.meta_description}/>
      <NavbarLogic />
      <div className="container blog-container mb-3">
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <BreadCrumb data={BreadCrumbData} />
          <span className='breadcrumb-li-tag breadcrumb'>
            <ol><li> {getBlogTitle(blog)}</li></ol>
          </span>
        </div>

        <div className="row">
          <div className="col-lg-12 col-ms-12">
            {blog && (
              <div className="card">
                <p className="text-danger text-center blog-date mt-5">
                  {getFormatedDateMonthName(blog.blog_date)}
                </p>
                <h3 className="text-center">{getBlogTitle(blog)}</h3>
                <div className="text-center mt-3">
                  {blog.blog_image && (
                    <img
                      src={blog.blog_image}
                      alt={blog.title}
                      className='blog-image mobile'
                    />
                  )}
                </div>
                <div className="p-5">
                  {/* <div
                      dangerouslySetInnerHTML={{
                        __html: getBlogDesc(blog),
                      }}
                    ></div> */}
                  {!isThaiLanguage ? <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getBlogDesc(blog),
                      }}
                    ></div>
                  </> :
                    <div className="thai-description-container" dangerouslySetInnerHTML={{ __html: sanitizeHtml(getBlogDesc(blog)) }}
                      />}

                </div>
              </div>
            )}

          </div>
        </div>
      </div>
      <Footer />
      </>}
    </>
  );
};

export default BlogDetail;
