const payment = '/payment';
const cartPayment = '/cart_payment';
const home = '/';
const shopNearMe = '/show_map';
const signIn = '/sign_in';
const signUp = '/sign_up';
const verify = '/verify';
const createNewPassword = '/create_new_password';
const services = '/services';
const search='/search/'
const bestSeller = '/Best Sellers';
const forgotPassword = '/forgot_password';
const serviceDescription = `/services/details`;
const about = '/about_us';
const privacy = '/privacy';
const contact = '/contact_us';
const faqs = '/faq';
const faqsSetting = '/setting';
const businessPolicy = '/business_policy';
const cancelPolicy = '/cancel_policy';
const termCondition = '/term_condition';
const users = '/user/*';
const vendors = '/bussiness/*';
const bussiness = '/bussiness/';
const user = '/user/';
const vendor = '/vendor';
const freelancer = '/freelancer';
const successCallback='/success_callback'
const getMobileResponse='/mobile'
const bookings = 'booking';
const accountDeletion = 'accounts/deletion';
const deleteRequest = 'accounts/deleted';
const cancelDeleteRequest = 'accounts/deletion/cancel';
const editProfile = 'edit_profile';
const favouriteShop = 'shopes/favourite/add';
const favouriteService = 'services/favourite/add';
const reviewShop = 'review_shop';
const addToCart = 'add_to_cart';
const cashback = 'cashback';
const referral = 'referral';
const reviewService = 'review_service';
const orderHistory = '/order_history';
const dashboard = 'dashbord';
const Profile = 'profile';
const bussinessbookings = 'booking';
const addService = 'services/add';
const buisinesseditService = 'services/edit';
const businessReview = 'review';
const bussinessServices = 'services';
const payout = 'payout';
const payoutSummary = 'payout/summary';
const teamMembers = 'team_members';
const finance = 'finance';
const accountDetails = 'account/details';
const openinghoursandpolicies = 'account/opening_hours_and_policies';
const branches = 'branches';
const calender = 'calender';
const blog = '/blog';
const blogDetails = '/blog-details';

export {
  payout,
  payoutSummary,
  bestSeller,
  businessReview,
  user,
  vendor,
  freelancer,
  bussiness,
  payment,
  cartPayment,
  home,
  shopNearMe,
  signIn,
  signUp,
  verify,
  createNewPassword,
  services,
  forgotPassword,
  serviceDescription,
  about,
  privacy,
  contact,
  faqs,
  faqsSetting,
  termCondition,
  users,
  vendors,
  bookings,
  accountDeletion,
  deleteRequest,
  cancelDeleteRequest,
  editProfile,
  favouriteShop,
  favouriteService,
  reviewShop,
  addToCart,
  cashback,
  referral,
  reviewService,
  dashboard,
  Profile,
  bussinessbookings,
  addService,
  buisinesseditService,
  bussinessServices,
  teamMembers,
  finance,
  accountDetails,
  openinghoursandpolicies,
  branches,
  calender,
  orderHistory,
  businessPolicy,
  cancelPolicy,
  successCallback,
  getMobileResponse,
  search,
  blog,
  blogDetails
};
