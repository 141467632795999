import { React, useNavigate, Locale, useEffect, useState, useRef, useMemo, useCallback, useLocation } from '../../libraries';
import { handleErrorResponse, getAllCategory, getSubCategory, getBestSellerSubCategory } from '../../services/index';
import { CustomDropDownCategories } from '../../common/dropdown/customDropDownCategories.js';
import { services } from '../../routes/routes.js';
import { encryptData } from '../../customHooks/urlEncyption.js';
import { urlFormat } from '../../customHooks/urlFormat.js';

const NavbarCategory = ({ showCategory, setHideDisplayName, hideDisplayName }) => {
  const [categoriesItems, setCategoriesItems] = useState([]);
  const [subCategoriesItems, setSubCategoriesItems] = useState([]);
  const [subCategoriesDatas, setSubCategoriesDatas] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [categoryName, setCategoryName] = useState({});
  const [subCategoryClickVisible, setSubCategoryVisible] = useState(window.innerWidth <= 991);
  const { t } = Locale();
  const controllerRef = useRef(new AbortController());
  const navigate = useNavigate();
  const location = useLocation();
  const prevCategoriesRef = useRef([]);

  const fetchData = useCallback(async () => {
    const controller = controllerRef.current;
    try {
      const { signal } = controller;
      const response = await getAllCategory({ signal });
      const categories = response.data;

      // Compare with previous data
      if (JSON.stringify(categories) !== JSON.stringify(prevCategoriesRef.current)) {
        prevCategoriesRef.current = categories; // Update previous data
        setCategoriesItems(categories);

        const subCategoryResponses = await Promise.all(
          categories.map(async (category) => {
            setCategoryName(category.name_en);
            return { category: category };
          }),
        );
        setSubCategoriesItems(subCategoryResponses);
        setServiceType(subCategoryResponses);
      }
    } catch (err) { }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setSubCategoryVisible(window.innerWidth <= 991);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => { }, [t]);

  useEffect(() => {
    const controller = controllerRef.current;
    fetchData();
    return () => {
      controller.abort();
    };
  }, [fetchData]);

  const menuRef = useRef(null);
  const overlayRef = useRef(null);

  const menuClick = () => {
    menuRef.current.classList.toggle('active');
    overlayRef.current.classList.toggle('active');

  };


  const closeMenuCategory = () => {
    if ((hideDisplayName)) {
      setHideDisplayName(false)
    }
    menuClick()
  }

  useEffect(() => {
    if ((showCategory)) {
      menuClick();
    }
  }, [showCategory])

  const handleCategoryClick = useCallback(
    async (categoryId, categoryNameEn, categoryNameTh) => {
      try {
        setCategoryName({ categoryNameEn, categoryNameTh });
        const { signal } = controllerRef.current;
        const response = await getSubCategory(categoryId, { signal });
        setSubCategoriesDatas(response.data);
      } catch (err) {
        handleErrorResponse(err);
      }
    },
    [],
  );

  const handleCategoryClickFun = (e, categoryName, showCategoryName, showCategoryNameThai) => {
    const formattedCategoryName = urlFormat(categoryName);
    const state = { showCategoryName, showCategoryNameThai };
    e.preventDefault();
    navigate(`${services}/${formattedCategoryName}`, { state });
  };

  const handleSubCategoryClick = useCallback(
    async (e, categoryId, subCategoryNameEn, subCategoryNameTh) => {
      e.preventDefault();
      e.stopPropagation();
      subCategoryClickVisible && menuClick();
      const encryptionData = {
        categoryId: categoryId,
        subCategoryName: { subCategoryNameEn, subCategoryNameTh },
        categoryName: categoryName,
      }

      const encryptedData = encryptData(encryptionData)
      const formattedCategoryName = urlFormat(categoryName.categoryNameEn)
      const formatSubCategoryName = urlFormat(subCategoryNameEn)
      navigate(`${services}/${formattedCategoryName}/${formatSubCategoryName}/${encryptedData}`);
    },
    [navigate, location.pathname, subCategoriesDatas, subCategoriesItems, categoriesItems, serviceType, categoryName, subCategoryClickVisible],
  );

  const memoizedCategoriesItems = useMemo(() => {
    const subCategoryCategoryIds = subCategoriesItems.map(
      (sub) => sub.category.id,
    );

    return categoriesItems.filter((category) =>
      subCategoryCategoryIds.includes(category.id),
    );
  }, [categoriesItems, subCategoriesItems]);

  const memoizedSubCategoriesDatas = useMemo(
    () => subCategoriesDatas,
    [subCategoriesDatas],
  );

  const onBestSellerEnter = useCallback(
    async (categoryId, categoryNameEn, categoryNameTh) => {
      try {
        setCategoryName({ categoryNameEn, categoryNameTh });
        const { signal } = controllerRef.current;
        const response = await getBestSellerSubCategory({ signal });
        response.data.data.forEach((obj) => {
          if (obj.name === 'Best Sellers') {
            setSubCategoriesDatas(obj.subcategories);
          }
        });
      } catch (err) {
        handleErrorResponse(err);
      }
    },
    [],
  );

  const handleMouseLeave = () => {
    setSubCategoriesDatas([]);
  };

  return (
    <CustomDropDownCategories
    closeMenuCategory={closeMenuCategory}
      setHideDisplayName={setHideDisplayName}
      onBestSellerEnter={onBestSellerEnter}
      menuRef={menuRef}
      overlayRef={overlayRef}
      menuClick={menuClick}
      subCategoryClickVisible={subCategoryClickVisible}
      items={memoizedCategoriesItems}
      onCategoryClick={handleCategoryClick}
      handleCategoryClickFun={handleCategoryClickFun}
      subCategoriesDatas={memoizedSubCategoriesDatas}
      onSubCategoryClick={handleSubCategoryClick}
      handleMouseLeave={handleMouseLeave}
    />
  );
};
export default NavbarCategory;