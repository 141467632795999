import {React,useEffect,useState,useLocation,useNavigate} from '../../../libraries/index';
import { AccountSettingHeader } from '../../pages/index';
import {openinghoursandpolicies,accountDetails,bussiness} from '../../../routes/routes';
const AccountSettingHeaders = () => {
  const [isPage, setIsPage] = useState({
    accountEditPage: false,
    openingHours: false,
  });
  const pathlocation = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathlocation.pathname === `${bussiness}${accountDetails}`) {
      setIsPage((prevPage) => ({
        ...prevPage,
        accountEditPage: true,
        openingHours: false,
      }));
    } else if (
      pathlocation.pathname === `${bussiness}${openinghoursandpolicies}`
    ) {
      setIsPage((prevPage) => ({
        ...prevPage,
        accountEditPage: false,
        openingHours: true,
      }));
    }
  }, [pathlocation.pathname]);

  const navigateFun = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <>
      <AccountSettingHeader navigateFun={navigateFun} isPage={isPage} />
    </>
  );
};

export default AccountSettingHeaders;