import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LineShareButton
} from 'react-share';
import './shareButton.css'; // Your custom styles

const ShareButtons = ({ url, title, body }) => {
  const shareUrl = process.env.REACT_APP_SHARE_URL;
  // Check if body is provided or not
  const bodyContent = body ? 
    `You can use referral code "${url}" to get a special discount on your bookings!
      Book your service now at Sura360: ${shareUrl}` 
    : `Book your service at Sura360 :` ;
  const separator = "\n\n"; // Custom separator for Whatsapp and Email

  return (
    <div className="share-buttons">
      <FacebookShareButton url={url} quote={title}>
        <span className="share-button facebook-button">
          <i className="bi bi-facebook"></i>
        </span>
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <span className="share-button twitter-button">
          <i className="bi bi-twitter"></i>
        </span>
      </TwitterShareButton>

      <WhatsappShareButton url={url} title={title} separator={separator}>
        <span className="share-button whatsapp-button">
          <i className="bi bi-whatsapp"></i>
        </span>
      </WhatsappShareButton>

      <EmailShareButton url={url} subject={title} body={bodyContent} separator={separator}>
        <span className="share-button email-button">
          <i className="bi bi-envelope-fill"></i>
        </span>
      </EmailShareButton>

      <LineShareButton url={url} title={title}>
        <span className="share-button line-button">
          <i className="bi bi-line"></i>
        </span>
      </LineShareButton>
    </div>
  );
};

export default ShareButtons;
