import { useEffect, message, useDispatch, useSelector, useState, useNavigate, Locale, toast, useParams } from '../../libraries';
import { handleErrorResponse, handleSuccessResponse, bookNow, getCashbackPerCheckout, dataForUniqueId, referralCodeVerification } from '../../services';
import jsonData from '../../jsonData/address.json';
import { isRequired, isRequiredCheckbox1, isRequiredRadioButton, isValidPhoneNumber } from '../../vendor/pages/Validation/Validation';
import { bookings, termCondition, user } from '../../routes/routes';
import { getGeoAddress, getLatLngByAddress } from '../../customHooks/locationHooks';
import { fetchUserReferralsInformation } from '../../redux/slice/user/userReferalSlice';
import { decryptData } from '../../customHooks/urlEncyption';

const BookNowPaymentLogic = () => {
  const navigate = useNavigate();
  const { t } = Locale();
  let initialValue = {
    fName: '',
    lName: '',
    email: '',
    phoneNumber: '',
    address: '',
    paymentType: '',
    termAndConditon: false,
    countrycode: '',
    checkbox: '',
    postalCode: '',
    city: '',
    latitude: '',
    longitude: '',
    landmark: '',
  };
  const [err, setErr] = useState({});
  const [discountPrice, setDiscountPrice] = useState();
  const [discountCashback, setDiscountCashback] = useState();
  const [suraCashback, setSuraCashback] = useState();
  const [referralCode, setReferralCode] = useState();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [subDistrictOption, setSubDistrictOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiCalculationData, setApiCalculationData] = useState();
  const [userInfo, setUserInfo] = useState();
  const [serviceDetails, setServiceDetails] = useState();
  const { unique_id } = useParams();
  const [serviceId, setServiceId] = useState();
  const [formValue, setFormValue] = useState(initialValue);
  const [isInvalidUrl, setInvalidUrl] = useState(false);
  const [calendarFormValue, setCalendarFormValue] = useState({})
  const [showModal, setShowModal] = useState(false)
  let data;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchUserReferralsInformation());
    };
    fetchData();
  }, [dispatch]);

  const userCashBackDetails = useSelector(
    (state) => state?.userCashBackDetails?.userCashBackDetails
  );

  useEffect(() => {
    const fetchData = async () => {
      if (unique_id) {
        try {
          let tempData = await calculationTotalData();
          setApiCalculationData(tempData);
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => { }, [discountPrice]);

  useEffect(() => {
    const getCashback = async () => {
      try {
        const response = await getCashbackPerCheckout();
        setSuraCashback(response.data.cashback_amount);
        return response;
      } catch (err) {
        handleErrorResponse(err);
      }
    };
    getCashback();
  }, []);

  const handleClosePermissionModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length > 0) {
      let phone_number =
        userInfo?.phone_number &&
        userInfo?.phone_number.split(' ')[1].toString();
      let tempCountryCode =
        userInfo?.phone_number &&
        userInfo?.phone_number.split(' ')[0].toString();
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        phoneNumber: phone_number,
        email: userInfo?.email,
        fName: userInfo?.first_name,
        lName: userInfo?.last_name,
        countrycode: tempCountryCode ? tempCountryCode : '+66',
        postalCode: userInfo.postcode?.toString(),
        city: userInfo?.city || '',
      }));
    }
  }, [userInfo]);

  const calculationTotalData = async () => {
    try {
      let response = await dataForUniqueId(unique_id);
      if (response?.data?.product_detail) {
        setServiceDetails(response?.data?.product_detail?.booking_services);
        setUserInfo(response?.data?.product_detail?.address);
        getDecryptDataFun(response?.data?.url_payload)
      }
      else {
        setInvalidUrl(true)
      }
      return response?.data?.product_detail?.booking;
    } catch (err) {
      setInvalidUrl(true)
      throw err;
    }
  };

  const getDecryptDataFun = (encryptData) => {
    const decryptedData = decryptData(encryptData);
    setServiceId(decryptedData?.serviceId)
    setCalendarFormValue((prev) => ({
      ...prev,
      [decryptedData?.serviceId]: {
        booking_date: decryptedData?.booking_date,
        booking_time: decryptedData?.booking_time,
        atServiceLocation: decryptedData?.selectedLocation,
        branch_id: decryptedData?.branchId
      }
    }))
  }

  const handleChange = (value) => {
    if (value?.target && value?.target?.name === 'termAndConditon') {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        termAndConditon: value?.target?.value ? true : false,
      }));
    } else {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        paymentType: value,
      }));

    }
  };

  const handleSearch = async (e) => {
    try {
      setReferralCode(e);
      let err = {};
      if (!e) {
        err.refralCode = `${t('payment.refralCodeErr')}`;
        setErr(err);
      }
      if (Object.keys(err).length === 0) {
        err = {};
        setErr(err);
        const response = await referralCodeVerification(e);
        handleSuccessResponse(response, response.data.message);
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const CashbackDiscount = async (e) => {
    if (e.target.checked) {
      try {
        const response = await getCashbackPerCheckout();
        setDiscountCashback(response.data.cashback_amount);
        return response;
      } catch (err) {
        handleErrorResponse(err);
      }
    }
  };

  const onCheckUserCashback = async (e) => {
    try {
      const response = await CashbackDiscount(e);
      setDiscountPrice(parseInt(discountPrice) + parseInt(suraCashback));
      if (!response.data.message) {
        if (e.target.checked) {
          setDiscountPrice(
            apiCalculationData?.total_paid_amount -
            response.data.cashback_amount,
          );
        } else {
          setDiscountPrice(discountPrice + response.data.cashback_amount);
        }
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const validation = (formValue) => {
    let checkErr = {};
    checkErr = isRequiredCheckbox1(
      formValue.termAndConditon,
      { ...checkErr },
      'termAndConditon',
      t,
    );
    checkErr = isRequiredRadioButton(
      formValue.paymentType,
      { ...checkErr },
      'paymentType',
      t,
    );
    checkErr = isRequired(
      formValue.address,
      { ...checkErr },
      'address',
      `${t('field.address')}`,
      t,
    );

    checkErr = isValidPhoneNumber(
      formValue.phoneNumber,
      { ...checkErr },
      'phoneNumber',
      `${t('field.phoneNumber')}`,
      '',
      t,
    );
    return checkErr;
  };

  const onChange = (name, value) => {
    if (name === 'postalCode') {
      const selectedPostalCode = value;
      const selectedPostalCodeData = jsonData.filter(
        (item) => item.postalCode === selectedPostalCode,
      );
      const subDistrictOP = selectedPostalCodeData.map((item) => ({
        value: item.districtNameEn,
        label: item.districtNameEn,
      }));
      setSubDistrictOption(subDistrictOP);
    }
    setFormValue({ ...formValue, [name]: value });
  };


  const handleSubmit = async (
    formValue,
    total_amount,
    serviceId,
  ) => {


    let err = {};
    err = validation(formValue);
    setErr(err);
    if (!Object.keys(err).length > 0) {
      setIsLoading(true);
      setButtonDisable(true);
      const payment_type = formValue.paymentType;
      let service_id = serviceId;
      let booking_date = calendarFormValue?.[service_id]?.booking_date;
      let booking_time = calendarFormValue?.[service_id]?.booking_time;
      let delivery_type = calendarFormValue?.[service_id]?.atServiceLocation;
      const bookingDetails = [
        {
          service_id,
          booking_date,
          booking_time,
          delivery_type,
        },
      ];
      data = {
        payment_type: payment_type,
        address: formValue.address,
        sub_district: formValue.city,
        postal_code: formValue.postalCode?.toString(),
        referral_code: referralCode,
        contact_number: formValue.countrycode + ' ' + formValue.phoneNumber,
        make_as_permanent_address: formValue.checkbox,
        total_amount: total_amount,
        applied_cashback: discountCashback ? true : false,
        booking_service_details: bookingDetails,
        latitude: formValue?.latitude,
        longitude: formValue?.longitude,
        landmark: formValue?.landmark,
      };
      try {
        let response = await bookNow(data);
        if (data.payment_type === "online") {
          const checkoutUrl = response.data.checkout_url;
          if (checkoutUrl && checkoutUrl !== 'null') {
            window.location.href = checkoutUrl;
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error('Unable to proceed with the payment. Please refresh the page or contact support.');
          }
        }

        else {
          localStorage.setItem('isSection', 'isBooking');
          setIsLoading(false);
          handleSuccessResponse(response, response?.data?.message)
          navigate(`${user}${bookings}`);
        }

      } catch (err) {
        setButtonDisable(false);
        setIsLoading(false);
        handleErrorResponse(err);
      }
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleClickUseMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            let response = await getGeoAddress(latitude, longitude);
            setFormValue((prev) => ({
              ...prev,
              address: response.address,
              landmark: response.landmark,
              latitude: latitude,
              longitude: longitude,
            }));
          } catch (error) {
            console.error('Error getting geocode response:', error);
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setShowModal(true)
          }
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };



  const handleChangePlace = async (e, onSelect) => {
    let longitude;
    let latitude;
    if (onSelect) {
      latitude = e?.geometry?.location?.lat();
      longitude = e?.geometry?.location?.lng();
      setFormValue((prev) => ({
        ...prev,
        address: e.formatted_address,
        latitude: latitude,
        longitude: longitude,
      }));
    } else {
      setFormValue((prev) => ({
        ...prev,
        address: e.target.value,
      }));
    }
    if (!latitude) {
      try {
        let response = await getLatLngByAddress(e?.target?.value);
        if (response?.lat) {
          setFormValue((prev) => ({
            ...prev,
            latitude: response?.lat,
          }));
        }
        if (response?.lng) {
          setFormValue((prev) => ({
            ...prev,
            longitude: response?.lng,
          }));
        }
      } catch (error) {
        console.error('Error getting latitude and longitude: ', error);
      }
    }
  };


  const handleNevigateTermCondition = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    window.open(termCondition, '_blank'); // Open the URL in a new tab
  };

  return {
    setApiCalculationData,
    handleNevigateTermCondition,
    handleChangePlace,
    handleClickUseMyLocation,
    filterOption,
    isLoading,
    formValue,
    handleSubmit,
    handleChange,
    err,
    handleSearch,
    onCheckUserCashback,
    discountPrice,
    buttonDisable,
    onChange,
    subDistrictOption,
    suraCashback,
    apiCalculationData,
    serviceDetails,
    setFormValue,
    userCashBackDetails,
    serviceId,
    isInvalidUrl,
    handleClosePermissionModal,
    showModal,
    calendarFormValue,
    setCalendarFormValue
  };
};
export default BookNowPaymentLogic;