import { useEffect, useState, useNavigate } from '../../../libraries/index';
import { GetOrganizationStatus } from '../index';
import { bussiness, payoutSummary } from '../../../routes/routes';
import { getPayoutOneYear, handleErrorResponse } from '../../../services';
import { urlFormat } from '../../../customHooks/urlFormat';
import { encryptData } from '../../../customHooks/urlEncyption';

const PayoutLogic = () => {
  const navigate = useNavigate();
  const { isOrganizationStatus } = GetOrganizationStatus();
  const [isLoader, setIsLoader] = useState(true);
  const [data, setData] = useState([]);
  const [isOpenSummary, setIsOpenSummary] = useState(false);

  useEffect(() => {
    const fetchPayoutData = async () => {
      let tempData = await getPayoutOneYearData();
      setData(tempData);
    };

    fetchPayoutData();
  }, []);

  const getPayoutOneYearData = async () => {
    try {
      setIsLoader(true);
      let response = await getPayoutOneYear();
      if (response?.data?.status_code === 204) {
        return [];
      }
      if (response.data.payouts) {
        return response.data.payouts;
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setIsLoader(false);
    }
  };

  const openSummaryPage = (e, month_en, month_thai) => {
    e.preventDefault();
    const encryptedData = encryptData({
      isMonthEn: month_en,
      isMonthThai: month_thai
    })
    navigate(`${bussiness}${payoutSummary}/${encryptedData}`);
  };

  const closeSummaryPage = () => {
    setIsOpenSummary(false);
  };

  return {
    isOrganizationStatus,
    isLoader,
    data,
    isOpenSummary,
    openSummaryPage,
    closeSummaryPage,
  };
};

export default PayoutLogic;