import './AccountSettingHeader.css';
import { React, PropTypes, Locale } from '../../../libraries/index';
import {openinghoursandpolicies,accountDetails,bussiness} from '../../../routes/routes';
const AccountSettingHeader = ({ navigateFun, isPage }) => {
  const { t } = Locale();
  return (
    <>
      <div className="AccountSettingHeader-container">
        <div className="user-booking-tab-outer-div account-details-tab">
          <span
            onClick={(e) => {
              navigateFun(e, `${bussiness}${accountDetails}`);
            }}
            className={`user-bookings-tab btn ms-1 ${isPage.accountEditPage ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'}`}
          >
            {t('vendor.accountDetail')}
          </span>
          <span
            onClick={(e) => {
              navigateFun(e, `${bussiness}${openinghoursandpolicies}`);
            }}
            className={`user-bookings-tab btn tab-2 ms-1 ${isPage.openingHours ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'}`}
          >
            {t('vendor.openHours')}
          </span>
        </div>
      </div>
    </>
  );
};
AccountSettingHeader.propTypes = {
  isPage: PropTypes.shape({
    accountEditPage: PropTypes.bool,
    openingHours: PropTypes.bool,
  }),
};

export default AccountSettingHeader;