import { React, useNavigate, FacebookLoginButton, LoginSocialFacebook } from '../../libraries';
import { handleErrorResponse, handleSuccessResponse, showUserDetails, facebookSignIn, showCartList, organizationStatus } from '../../services';
import { Profile, bussiness, dashboard, home } from '../../routes/routes';

const Facebook = ({ className, text }) => {
  const navigate = useNavigate();

  const customerSignIn = async (response) => {
    const cartResponse = await showCartList();
    let cartCount = cartResponse?.data?.data?.length;
    localStorage.setItem('status', response.data.user.status);
    if (cartCount === null || isNaN(cartCount) || cartCount === undefined) {
      cartCount = 0;
    }

    localStorage.setItem('cartCount', cartCount);
    let name = response.data.user.first_name;
    let lastName = response.data.user.last_name
      ? response.data.user.last_name
      : '';
    let fullName = name + ' ' + lastName;
    localStorage.setItem('name', fullName);
    localStorage.setItem('userId', response.data.user.id);
    localStorage.setItem('profilePhoto', response.data.image_url ? response.data.image_url : '');
    handleSuccessResponse(response, response.data.message);
    navigate(home);
  }

  const vendorSignIn = async (response) => {
    const getVerification = await organizationStatus();
    if (getVerification.data.status === 'verified') {
      localStorage.setItem('status', 'verified');
    } else if (
      getVerification.data.account &&
      getVerification.data.finance
    ) {
      localStorage.setItem('status', 'underReview');
    } else {
      localStorage.setItem('status', 'completProfile');
    }
    localStorage.setItem('userId', response.data.user.id);
    localStorage.setItem('orgId', response.data.organization_id);
    if (response.data.image_url) {
      localStorage.setItem(
        'profilePhoto',
        response.data.image_url,
      );
    }
    localStorage.setItem('isSection', 'isDashboard');
    localStorage.setItem(
      'name',
      response.data.user.first_name +
      ' ' +
      response.data.user.last_name,
    );
    handleSuccessResponse(response, response.data.message);
    window.history.pushState(null, null, `${bussiness}${dashboard}`);
    navigate(`${bussiness}${dashboard}`);
    const disableBack = (event) => {
      window.history.pushState(null, null, `${bussiness}${dashboard}`);
      event.preventDefault();
    };
    window.addEventListener('popstate', disableBack);
    return () => window.removeEventListener('popstate', disableBack);
  }

  const teamMemberSignIn = async (response) => {
    const getShowResponse = await showUserDetails();
    localStorage.setItem('status', 'verified');
    localStorage.setItem('userId', response?.data?.user?.id);
    if (getShowResponse.data.image_url) {
      localStorage.setItem(
        'profilePhoto',
        getShowResponse.data.image_url,
      );
    }
    localStorage.setItem('isSection', 'isVendorProfile');

    localStorage.setItem(
      'name',
      response?.data?.user?.first_name +
      ' ' +
      response?.data?.user?.last_name,
    );
    handleSuccessResponse(response, response?.data?.message);
    window.history.pushState(null, null, `${bussiness}${Profile}`);
    navigate(`${bussiness}${Profile}`);
    const disableBack = (event) => {
      window.history.pushState(null, null, `${bussiness}${Profile}`);
      event.preventDefault();
    };
    window.addEventListener('popstate', disableBack);
    return () => window.removeEventListener('popstate', disableBack);
  }

  return (
    <>
      <div className={className}>
        <LoginSocialFacebook
          appId="384031517363206"
          onResolve={async (res) => {
            try {
              const response = await facebookSignIn({ token: res.data });
              if (response && response.data.access_token) {
                (response?.data?.initial_user) && localStorage.setItem('firstCashback', true)
                const responseUserDetails = await showUserDetails();
                if (response?.data?.role === 'Freelancer') {
                  localStorage.setItem('type', true);
                  localStorage.setItem('role', 'Vendor');
                }
                else {
                  localStorage.setItem('role', response.data.role);
                }
                if (response.data.role === 'Customer') {
                  customerSignIn(responseUserDetails);
                }
                else if (response.data.role === 'Vendor') {
                  vendorSignIn(responseUserDetails);
                }
                else if (response.data.role === 'TeamMember') {
                  teamMemberSignIn(responseUserDetails)
                }
              }
              else {
                console.log('invalid users');
              }
            } catch (err) {
              handleErrorResponse(err);
            }
          }}
          onReject={(err) => {
            console.error('Facebook login rejected:', err);
          }}
          redirectUri="http://192.168.1.44:3000/"
        >
          <FacebookLoginButton
            className={`sign-in-facebook-btn ${className}`}
            style={{ height: '2rem', fontSize: '13px', width: '11rem' }}
            text={text}
          />
        </LoginSocialFacebook>
      </div>
    </>
  );
};
export default Facebook;