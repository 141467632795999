import { React, useLocation, moment, useNavigate, useEffect, useState } from '../../libraries';
import { BookNowMoadl } from '../../pages/BookNowModal/BookNowModal'; // Adjust path as per your project structure
import { favouriteService, payment, user } from '../../routes/routes'; // Assuming these are your route constants
import { encryptData } from '../../customHooks/urlEncyption';
import { getCalculationPickAndTotalAmount, handleErrorResponse } from '../../services';
const BookNowModalLogic = ({
  index,
  type,
  className,
  service_id,
  vender_details,
  service_details,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    vender_details?.service_at_bussiness_location ? 'at_shop' : 'at_my_location'
  );
  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const [openBookNowModal, setOpenBookNowModal] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [confirmBtnDisable, setConfirmBtnDisable] = useState(true);
  const [serviceBranch, setServiceBranch] = useState(
    type === 'AddToFavourite' ? vender_details?.branch_details : null
  )
  const [vendorOpeningHours, setVendorOpeningHours] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const businessHoursKey = type === 'AddToFavourite' ? 'vendor_business_hours' : 'bussiness_hours';
    if (vender_details && vender_details[businessHoursKey]) {
      const businessHours = vender_details[businessHoursKey];

      setVendorOpeningHours({
        monday: businessHours.monday?.shop_open === 'true',
        tuesday: businessHours.tuesday?.shop_open === 'true',
        wednesday: businessHours.wednesday?.shop_open === 'true',
        thursday: businessHours.thursday?.shop_open === 'true',
        friday: businessHours.friday?.shop_open === 'true',
        saturday: businessHours.saturday?.shop_open === 'true',
        sunday: businessHours.sunday?.shop_open === 'true',
      });
    } else {
      setVendorOpeningHours({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      });
    }


    if (type !== 'AddToFavourite') {
      let tempserviceBranch = [
        { branchId: 101, branchName: 'yn road', branchAddress: 'yn road malwa mill indore 452003' },
        { branchId: 102, branchName: 'vijay nagar', branchAddress: 'vijay nagar c21 mall indore 452003' },
        { branchId: 103, branchName: 'bhavarkua', branchAddress: 'bhavarkua khandwa road indore 452003' },
        { branchId: 104, branchName: 'mr10', branchAddress: 'mr10 aurvindo road indore 452003' }
      ];
      let tempOption = tempserviceBranch.map(obj => ({
        value: obj.branchId,
        label: obj.branchName
      }));
      setServiceBranch(tempserviceBranch)
      setBranchOptions(tempOption);
      setSelectedBranch(tempOption[0]?.value);
    }


  }, [type, index, vender_details]);

  const showBookModal = () => {
    if (role === 'Customer') {
      setIsModalVisible(true);
      setOpenBookNowModal(!openBookNowModal);
    } else {
      setShowModal(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShowModal(false);
  };

  const handleLocationChange = (value) => {
    setSelectedLocation(value);
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value)
  }

  useEffect(() => {
    if (serviceBranch) {
      let tempOption = [];
      serviceBranch?.map((obj) => (
        tempOption.push({ value: obj?.id, label: obj?.name })))
      setBranchOptions(tempOption);
      setSelectedBranch(tempOption[0]?.value);
    }
  }, [serviceBranch])


  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem('isSection');
    const booking_date = selectedDateTime?.format('DD-MM-YYYY');
    const booking_time = selectedDateTime?.format('HH:mm');
    const serviceId = type ? vender_details.service_id : service_details?.[index ?? 0]?.service_id;
    const dataToEncrypt = {
      serviceId,
      booking_date,
      booking_time,
      selectedLocation,
      branchId: selectedBranch
    };
    const encryptedData = encryptData(dataToEncrypt);
    handleCalculationApi(encryptedData, serviceId, booking_date, booking_time, selectedBranch)
  };


  const handleCalculationApi = async (encryptedData, serviceId, booking_date, booking_time, branchId) => {
    try {
      let bookingDetails;
      bookingDetails = [
        {
          service_id: serviceId,
          booking_date: booking_date,
          booking_time: booking_time,
          branch_id: branchId && branchId
        },
      ];
      let param = { booking_service_details: bookingDetails, checkout_payload: encryptedData };
      let response = await getCalculationPickAndTotalAmount(param);
      navigate(`${payment}/${response.data.unique_checkout_url}`);
    }
    catch (err) {
      handleErrorResponse(err)
    }

  }

  const disabledDate = (current) => {
    if (current < moment().startOf('day')) {
      return true;
    }
    const dayName = current.format('dddd').toLowerCase();
    return !vendorOpeningHours[dayName];
  };

  const handleDisableTime = (current, type, product) => {
    if (type) {
      setConfirmBtnDisable(false);
      setSelectedDateTime(current);
    }
    if (!current) {
      return null;
    }
    const selectedDate = current.clone().startOf('day');
    const dayOfWeek = selectedDate.format('dddd').toLowerCase();
    const openTime = moment(product?.bussiness_hours[dayOfWeek]?.open, 'HH:mm');
    const closeTime = moment(product?.bussiness_hours[dayOfWeek]?.close, 'HH:mm');


    if (current.isSame(selectedDate, "day")) {
      return {
        disabledHours: () => {
          const hours = [];
          for (let i = 0; i < 24; i++) {
            const currentHour = moment().hour(i);
            if (currentHour.isBefore(openTime) || currentHour.isAfter(closeTime)) {
              hours.push(i);
            }
          }
          return hours;
        },
        disabledMinutes: (selectedHour) => {
          if (selectedHour < openTime.hour() || selectedHour > closeTime.hour()) {
            return [...Array(60).keys()]; // Disable all minutes
          }
          if (selectedHour === openTime.hour()) {
            return [...Array(openTime.minute()).keys()]; // Disable minutes before open time
          }
          if (selectedHour === closeTime.hour()) {
            return [...Array(closeTime.minute() + 1).keys()]; // Disable minutes after close time
          }
          return [];
        },
      };
    }

    return null;
  };



  const handleDisableTimeFavouritePage = (current, type, product) => {
    if (type) {
      setSelectedDateTime(current);
      setConfirmBtnDisable(false);
    }
    if (!current) {
      return null;
    }
    const selectedDate = current.clone().startOf('day');
    const dayOfWeek = selectedDate.format('dddd').toLowerCase();
    const openTime = moment(product?.vendor_business_hours[dayOfWeek]?.open, 'HH:mm');
    const closeTime = moment(product?.vendor_business_hours[dayOfWeek]?.close, 'HH:mm');
    if (current.isSame(selectedDate, 'day')) {
      return {
        disabledHours: () => {
          const hours = [];
          for (let i = 0; i < 24; i++) {
            const currentHour = moment().hour(i);
            if (currentHour.isBefore(openTime) || currentHour.isAfter(closeTime)) {
              hours.push(i);
            }
          }
          return hours;
        },
        disabledMinutes: (selectedHour) => {
          if (selectedHour < openTime.hour() || selectedHour > closeTime.hour()) {
            return [...Array(60).keys()]; // Disable all minutes
          }
          if (selectedHour === openTime.hour()) {
            return [...Array(openTime.minute()).keys()]; // Disable minutes before open time
          }
          if (selectedHour === closeTime.hour()) {
            return [...Array(closeTime.minute() + 1).keys()]; // Disable minutes after close time
          }
          return [];
        },
      };
    }
    return null;
  };

  const isFavouritePage = location.pathname === `${user}${favouriteService}`;
  return (
    <BookNowMoadl
      selectedBranch={selectedBranch}
      branchOptions={branchOptions}
      handleBranchChange={handleBranchChange}
      selectedLocation={selectedLocation}
      confirmBtnDisable={confirmBtnDisable}
      vender_details={vender_details}
      handleDisableTime={!isFavouritePage ? handleDisableTime : handleDisableTimeFavouritePage}
      vendorOpeningHours={vendorOpeningHours}
      openBookNowModal={openBookNowModal}
      showBookModal={showBookModal}
      isModalVisible={isModalVisible}
      showModal={showModal}
      className={className}
      uniqueId={`date-popup-${service_id}`}
      handleCancel={handleCancel}
      handleOk={handleSubmit}
      handleLocationChange={handleLocationChange}
      disabledDate={disabledDate}
      serviceLocationHome={vender_details?.service_at_customer_location}
      serviceLocationShop={vender_details?.service_at_bussiness_location}
      setShowModal={setShowModal}
    />
  );
};
export default BookNowModalLogic;