import './ContactUs.css';
import { React, PropTypes, Locale, ReCAPTCHA } from '../../libraries/index';
import { AboutUsImage } from '../../assets/Images';
import { CustomSelect, Footer } from '../../common';
import { NavbarLogic } from '../../components/index';
import { BreadCrumb } from '../BreadCrumb/BreadCrumb';
import { MetaTag } from "../../customHooks/metaKeywords";
const React_App_Recaptcha = process.env.REACT_APP_RECAPTCHA;
const ContactUs = ({
  breadCrumbData,
  formValues,
  onHandleChange,
  onHandleSubmit,
  list,
  formErrors,
  setFormValues
}) => {
  const { t } = Locale();
  return (
    <>
      <MetaTag custom_title={"ContactUs"} custom_description={t("field.feelFree")}/>
      <NavbarLogic />
      <section className="container mb-5 about-us-outer-container">
        <BreadCrumb data={breadCrumbData} />
        <div className="contact_inner">
          <div className="row">
            <div className="col-md-10">
              <div className="contact_form_inner">
                <div className="contact_field">
                  <div className="venue-name-h3">{t("field.contactUs")}</div>
                  <p>{t("field.feelFree")}</p>
                  <input
                    type="text"
                    className="form-control form-group"
                    placeholder={`${t("field.name")}*`}
                    id="name"
                    name="name"
                    value={formValues.name}
                    onChange={onHandleChange}
                  />
                  <small className="small-err">{formErrors.name}</small>
                  <input
                    type="email"
                    name="email"
                    className="form-control form-group mt-3"
                    value={formValues.email}
                    onChange={onHandleChange}
                    placeholder={`${t("field.semail")} ${t("field.address")}*`}
                  />
                  <small className="small-err">{formErrors.email}</small>
                  <CustomSelect
                    name="selectCategory"
                    className="form-control form-group mt-3"
                    value={formValues.selectCategory}
                    placeholder={`${t("header.select")} ${t(
                      "ServiceField.category"
                    )}*`}
                    onChange={onHandleChange}
                    options={list}
                  />
                  <small className="small-err">
                    {formErrors.selectCategory}
                  </small>
                  <input
                    type="text"
                    name="subject"
                    value={formValues.subject}
                    onChange={onHandleChange}
                    placeholder={`${t("field.subject")}*`}
                    className="form-control form-group mt-3"
                  />
                  <small className="small-err">{formErrors.subject}</small>
                  <textarea
                    className="form-control form-group mt-3"
                    name="description"
                    value={formValues.description}
                    onChange={onHandleChange}
                    placeholder={t("ServiceField.description")}
                  ></textarea>
                  <div className="mt-4 recaptcha">
                    <ReCAPTCHA
                      sitekey={`${React_App_Recaptcha}`}
                      value={formValues.capture}
                      name="capture"
                      onChange={() => {
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          capture: 'true',
                        }));
                      }}
                      error={
                        formErrors.capture && (
                          <p className="small-err">{formErrors.capture}</p>
                        )
                      }
                    />
                  </div>

                  <div>
                    <small className="small-err">
                      {formErrors.capture ? formErrors.capture : false}
                    </small>
                  </div>
                  <button
                    onClick={onHandleSubmit}
                    className="contact_form_submit"
                  >
                    {t("button.submitButton")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="right_conatct_social_icon d-flex align-items-end">
                <div className="socil_item_inner d-flex"></div>
              </div>
            </div>
          </div>
          <div
            className="contact_info_sec"
            style={{ backgroundImage: `url(${AboutUsImage})` }}
          ></div>
        </div>
      </section>
      <Footer />
    </>
  );
};
ContactUs.propTypes = {
  formValues: PropTypes.object,
  onHandleChange: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  list: PropTypes.array,
  formErrors: PropTypes.object,
};
export default ContactUs;