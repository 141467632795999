import { React, moment, useEffect, useState, Locale } from '../../libraries';
import { CheckoutCalendarModal } from "../../pages";
import { getCalculationPickAndTotalAmount, handleErrorResponse, serviceIdVendorDetails } from '../../services';

const CheckoutCalendarModalLogic = ({ setApiCalculationData, calendarFormValue, setCalendarFormValue, isModalVisible, handleCancel, selectServiceDetails }) => {
    const { t } = Locale();
    const [selectedDateTime, setSelectedDateTime] = useState();
    const [showAtLocation, setShowLocation] = useState();
    const [options, setOptions] = useState([]);
    const [vendorOpeningHours, setVendorOpeningHours] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });

    useEffect(() => {
        getVendorData();
        setShowLocation(calendarFormValue?.[selectServiceDetails?.service_id]?.atServiceLocation)
    }, [selectServiceDetails?.service_id]);


    const getVendorData = async () => {
        try {
            const response = await serviceIdVendorDetails(selectServiceDetails?.service_id);
            setLocationOption(response?.data);
            setBusinessHourseFun(response?.data);
        }
        catch (err) {
            handleErrorResponse(err)
        }
    }

    const handleDisableTime = (current, type, product) => {
        if (type) {
            setSelectedDateTime(current);
        }
        if (!current) {
            return null;
        }

        const product1 = {
            friday: { open: '11:00', close: '22:00', shop_open: 'true' },
            monday: { open: '11:00', close: '22:00', shop_open: 'false' },
            saturday: { open: '09:00', close: '14:00', shop_open: 'false' },
            sunday: { open: '02:00', close: '03:30', shop_open: 'false' },
            thursday: { open: '11:00', close: '22:00', shop_open: 'true' },
            tuesday: { open: '11:00', close: '22:00', shop_open: 'false' },
            wednesday: { open: '11:00', close: '22:00', shop_open: 'true' }
        }
        const selectedDate = current.clone().startOf('day');
        const dayOfWeek = selectedDate.format('dddd').toLowerCase();
        const openTime = moment(product1?.[dayOfWeek]?.open, 'HH:mm');
        const closeTime = moment(product1?.[dayOfWeek]?.close, 'HH:mm');


        if (current.isSame(selectedDate, "day")) {
            return {
                disabledHours: () => {
                    const hours = [];
                    for (let i = 0; i < 24; i++) {
                        const currentHour = moment().hour(i);
                        if (currentHour.isBefore(openTime) || currentHour.isAfter(closeTime)) {
                            hours.push(i);
                        }
                    }
                    return hours;
                },
                disabledMinutes: (selectedHour) => {
                    if (selectedHour < openTime.hour() || selectedHour > closeTime.hour()) {
                        return [...Array(60).keys()]; // Disable all minutes
                    }
                    if (selectedHour === openTime.hour()) {
                        return [...Array(openTime.minute()).keys()]; // Disable minutes before open time
                    }
                    if (selectedHour === closeTime.hour()) {
                        return [...Array(closeTime.minute() + 1).keys()]; // Disable minutes after close time
                    }
                    return [];
                },
            };
        }

        return null;
    };

    const disabledDate = (current) => {
        if (current < moment().startOf('day')) {
            return true;
        }
        const dayName = current.format('dddd').toLowerCase();
        return !vendorOpeningHours[dayName];
    };

    const handleSubmit = async (event) => {
        const booking_date = selectedDateTime?.format('DD-MM-YYYY');
        const booking_time = selectedDateTime?.format('HH:mm');
        setCalendarFormValue((prev) => ({
            ...prev,
            [selectServiceDetails?.service_id]: {
                ...prev[selectServiceDetails?.service_id], // Keep existing values
                atServiceLocation: showAtLocation, // Use selected location
            }
        }));

        await setCalendarFormValue((prev) => ({
            ...prev,
            [selectServiceDetails?.service_id]: {
                ...prev[selectServiceDetails?.service_id], // Keep existing values
                booking_date, // Update booking_date
                booking_time, // Update booking_time
            }
        }));
        
        await getCalculationData(booking_date, booking_time)
        await handleCancel()
    }

    const getCalculationData = async (booking_date, booking_time) => {
        try {
            const booking_service_details = Object.keys(calendarFormValue).map(service_id => {
                if (service_id == selectServiceDetails?.service_id) {
                    return {
                        service_id: selectServiceDetails?.service_id,  // Use selected service_id
                        booking_date: booking_date,
                        booking_time: booking_time
                    };
                } else {
                    return {
                        service_id: parseInt(service_id),  // Convert service_id string to number
                        booking_date: calendarFormValue[service_id].booking_date,
                        booking_time: calendarFormValue[service_id].booking_time
                    };
                }
            });

            let param = { booking_service_details };
            let response = await getCalculationPickAndTotalAmount(param);
            setApiCalculationData(response?.data?.booking);

        }
        catch (err) {
            handleErrorResponse(err)
        }
    }

    const handleLocationChange = (id, value) => {
        setShowLocation(value)

    }

    const setLocationOption = (response) => {
        const options = [];
        if ((response?.service_at_customer_location)) {
            options.push({ value: 'at_my_location', label: t('field.atHome') });
        }
        if ((response?.service_at_bussiness_location)) {
            options.push({ value: 'at_shop', label: t('field.atShop') });
        }
        setOptions(options);
    };

    const setBusinessHourseFun = (response) => {
        if (response?.vendor_bussiness_hours) {
            const businessHours = response?.vendor_bussiness_hours;
            setVendorOpeningHours({
                monday: businessHours.monday?.shop_open === 'true',
                tuesday: businessHours.tuesday?.shop_open === 'true',
                wednesday: businessHours.wednesday?.shop_open === 'true',
                thursday: businessHours.thursday?.shop_open === 'true',
                friday: businessHours.friday?.shop_open === 'true',
                saturday: businessHours.saturday?.shop_open === 'true',
                sunday: businessHours.sunday?.shop_open === 'true',
            });
        } else {
            setVendorOpeningHours({
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            });
        }
    }

    const handleDateChange = (date) => {
        setSelectedDateTime(date);
    };


    return <>
        <CheckoutCalendarModal
            showAtLocation={showAtLocation}
            isModalVisible={isModalVisible}
            handleCancel={handleCancel}
            selectServiceDetails={selectServiceDetails}
            handleDisableTime={handleDisableTime}
            handleOk={handleSubmit}
            handleLocationChange={handleLocationChange}
            disabledDate={disabledDate}
            uniqueId={`date-popup-${selectServiceDetails?.service_id}-${performance.now()}`}
            options={options}
            selectedDateTime={selectedDateTime}
            onDateChange={handleDateChange} // Pass the handler
            calendarFormValue={calendarFormValue}
        />
    </>
}

export default CheckoutCalendarModalLogic;