import { apiCall } from './base';

const bookNow = async (params) => {
  const response = await apiCall('customer/bookings', 'POST', params);
  return response;
};

const getCheckoutUrl = async (params) => {
  const response = await apiCall(`customer/get_checkout_url?checkout_id=${params}`, 'get');
  return response;
};

const serviceIdVendorDetails = async (params) => {
  const response = await apiCall(`customer/service/${params}`, 'GET');
  return response;
}

const bookingPayment = async (params) => {
  const response = await apiCall(
    'customer/bookings/payment_callback',
    'POST',
    params,
  );
  return response;
};


const editBookings = async (params) => {
  const response = await apiCall(
    `customer/bookings/${params.id}`,
    'PUT',
    params.formData,
  );
  return response;
};

export { bookNow, editBookings, bookingPayment, getCheckoutUrl, serviceIdVendorDetails };
