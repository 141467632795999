export const getFormatedDate = () => {

}

export const getFormatedDateMonthName = (date) => {
    return new Date(date).toLocaleDateString(
      "en-GB", // This sets the date format to day/month/year
      {
        month: "short",
        day: "2-digit",
        year: "numeric",
        timeZone: "Asia/Bangkok", // Specifies the Bangkok timezone
      }
    );
  };

export const getFormatTime = () => {

}