import './BookNowModal.css'; // Import your CSS file
import { React, dayjs, Locale, useEffect, useState, Modal, Button, DatePicker, Select } from '../../libraries';
import { SignIn } from '../../components';

export const BookNowMoadl = ({
  disabledDate,
  confirmBtnDisable,
  vendorOpenignHoursFunction,
  isModalVisible,
  showModal,
  className,
  showBookModal,
  handleOk,
  handleCancel,
  uniqueId,
  handleLocationChange,
  handleDisableTime,
  vender_details,
  serviceLocationHome,
  serviceLocationShop,
  setShowModal,
}) => {
  const { t } = Locale();
  const [options, setOptions] = useState([]);
  const dateFormat = 'YYYY-MM-DD';
  const handleDisableTimed = (current, type) => handleDisableTime(current, type, vender_details);
  const setLocationOption = () => {
    const options = [];

    if (serviceLocationHome) {
      options.push({ value: 'at_my_location', label: t('field.atHome') });
    }
    if (serviceLocationShop) {
      options.push({ value: 'at_shop', label: t('field.atShop') });
    }

    setOptions(options);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        await vendorOpenignHoursFunction();
      } catch (error) { }
    };

    fetchData();
    setLocationOption();
  }, [serviceLocationHome, serviceLocationShop]);

  return (
    <>
      <div className="bookNow book-now-modal-new">
        <div
          className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <SignIn setShowModal={setShowModal} showModal={showModal} />
        </div>
      </div>
      <Button onClick={showBookModal} className={`book-now-modal-book-btn ${className}`}>
        {t('button.bookNowButton')}
      </Button>
      <Modal
        title={t('field.scheduleDate')}
        open={isModalVisible}
        className="add-to-cart-modal book-now-modal-date-picker"
        footer={[
          <Button key="1" onClick={handleCancel}>
            {t('button.cancelButton')}
          </Button>,
          <Button
            key="3"
            className={`confirm ${confirmBtnDisable && 'btn-disable'}`}
            onClick={(e) => handleOk(e)}
          >
            {t('field.confirm')}
          </Button>,
        ]}
      >

        <div className="row ime-pick-btn book-now-modal-date-picker-outer-div book-now-modal-single-booking">
          <div className="col-12 book-now-modal-calendar-col-9 hide-book-btn-modal-new">
            <div className="d-flex justify-content-end">
              <div>
                <Select
                  defaultValue={
                    serviceLocationShop
                      ? t('userBooking.atShop')
                      : t('field.atHome')
                  }
                  onChange={handleLocationChange}
                  options={options}
                  style={{ width: 120 }}
                />
              </div>
            </div>
            <DatePicker
              className="custom-datepicker book-now-modal-date-picker hide-ok-button-new" // Apply CSS class here
              getPopupContainer={() => document.getElementById(uniqueId)}
              popupStyle={{
                position: 'relative',
              }}
              showTime={{ format: 'HH:mm' }}
              open={true}
              showNow={false}
              disabledDate={disabledDate}
              disabledTime={handleDisableTimed}
              minDate={dayjs('2019-08-01', dateFormat)}
            />

            <div id={uniqueId} />
          </div>
        </div>
      </Modal>
    </>
  );
};
