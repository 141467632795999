import '../BookNowModal/BookNowModal.css'; // Import your CSS file
import { React, Modal, Button, DatePicker, Select, dayjs, Locale, timezone, utc } from '../../libraries';

const CheckoutCalendarModal = ({
    isModalVisible,
    handleCancel,
    selectServiceDetails,
    handleDisableTime,
    handleOk,
    handleLocationChange,
    disabledDate,
    uniqueId,
    options,
    selectedDateTime,
    onDateChange,
    calendarFormValue,
    showAtLocation
}) => {
    const { t } = Locale();
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const handleDisableTimed = (current, type) => handleDisableTime(current, type, selectServiceDetails);
    const serviceDetails = calendarFormValue?.[selectServiceDetails?.service_id];
    const bookingDateFromCalendar = serviceDetails?.booking_date ? dayjs(serviceDetails.booking_date, "DD-MM-YYYY") : null;
    const bookingTimeFromCalendar = serviceDetails?.booking_time ? dayjs(serviceDetails.booking_time, "HH:mm") : null;
    const bookingDateTime = bookingDateFromCalendar && bookingTimeFromCalendar ? bookingDateFromCalendar.hour(bookingTimeFromCalendar.hour()).minute(bookingTimeFromCalendar.minute()).second(bookingTimeFromCalendar.second()) : null;

    return <>
        <Modal
            title={t('field.scheduleDate')}
            open={isModalVisible}
            className="add-to-cart-modal book-now-modal-date-picker"
            footer={[
                <Button key="1" onClick={handleCancel}>
                    {t('button.cancelButton')}
                </Button>,
                <Button
                    key="3"
                    className={`confirm `}
                    onClick={(e) => handleOk(e)}
                >
                    {t('field.confirm')}
                </Button>,
            ]}
        >
            <div className="row ime-pick-btn book-now-modal-date-picker-outer-div book-now-modal-single-booking">
                <div className="col-12 book-now-modal-calendar-col-9 hide-book-btn-modal-new">
                    <div className="d-flex justify-content-end">
                        <div>
                            <Select
                                onChange={(e) => handleLocationChange(selectServiceDetails?.service_id, e)}
                                options={options}
                                style={{ width: 120 }}
                                value={showAtLocation}
                                // value={calendarFormValue?.[selectServiceDetails?.service_id]?.atServiceLocation}
                            />
                        </div>
                    </div>

                    <DatePicker
                        className="custom-datepicker book-now-modal-date-picker hide-ok-button-new"
                        getPopupContainer={() => document.getElementById(uniqueId)}
                        popupStyle={{
                            position: 'relative',
                        }}
                        showTime={{ format: 'HH:mm' }}
                        open={true}
                        showNow={false}
                        disabledDate={disabledDate}
                        disabledTime={handleDisableTimed}
                        minDate={dayjs('2019-08-01', 'YYYY-MM-DD')}
                        value={selectedDateTime || bookingDateTime} // Use selectedDateTime as the value
                        onChange={onDateChange} // Call the onChange handler
                    />
                    <div id={uniqueId} />
                </div>
            </div>
        </Modal>
    </>
}

export default CheckoutCalendarModal;