import { apiCall } from './base';

const getBlog = async (currentLocation) => {
  const response = await apiCall(
    `public/blogs`,
    'GET',
  );
  return response;
};

export { getBlog };
