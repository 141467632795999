import './pageNotFound.css'
import { Locale, React, useEffect, useNavigate, useState } from '../../libraries';
import { bussiness, dashboard, home } from '../../routes/routes';
import { PageNotFoundImage, PageNotFoundImageIpad, PageNotFoundImageMobile } from '../../assets/Images';
const PageNotFound = () => {
    const { t } = Locale();
    const navigate = useNavigate();
    const role = localStorage.getItem("role")

    const getPageNotFoundImage = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth > 768) return PageNotFoundImage;
        if (screenWidth > 420) return PageNotFoundImageIpad;
        return PageNotFoundImageMobile;
    };

    const [imageUrl, setImageUrl] = useState(getPageNotFoundImage());

    useEffect(() => {
        const handleResize = () => setImageUrl(getPageNotFoundImage());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigateHome = (e) => {
        e.preventDefault();
        if ((role === 'Vendor' || role === 'freelancer' || role === 'TeamMember'))
            navigate(`${bussiness}${dashboard}`)
        else navigate(home)
    }

    return <>
        <div className='page-not-found-outer-div'>
            <img
                alt={'page-not-found-img'}
                src={imageUrl}
                className='page-not-found-img'
            />
            <button
                onClick={(e) => navigateHome(e)}
                className='page-not-found-btn'
            >
                <i className="bi bi-arrow-left mt-4 mb-4 "></i>&nbsp;  {t('button.backToHome')}
            </button>

        </div>
    </>
}
export default PageNotFound;