import { Locale, useLocation } from '../../libraries';
import { accountDeletion, user } from '../../routes/routes';
import { Heading } from '../../common';
const DeletionRequestHeader = () => {
  const { t } = Locale();
  const location = useLocation();
  return (
    <>
      {location.pathname === `${user}${accountDeletion}` ? (
        <>
          <Heading text={t('userEdit.accountDeletion')} icon="bi bi-trash3" />
        </>
      ) : (
        <>
          <Heading text={t('userEdit.deletionRequest')} icon="bi bi-trash3" />
        </>
      )}
    </>
  );
};
export default DeletionRequestHeader;