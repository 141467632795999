import { Helmet } from "react-helmet";
import React, { useEffect, useState } from 'react';

// Descriptions and Titles
const descriptionEN = 'Discover SURA360, your ultimate destination for beauty, relaxation, and dental services. Easily book massage therapies and beauty treatments from top-rated professionals at your home, hotel, or spa. Experience certified therapists and beauticians on-demand.';
const descriptionTH = 'พบกับ SURA360 จุดหมายปลายทางที่สมบูรณ์แบบสำหรับความงาม การผ่อนคลาย และบริการทันตกรรม จองบริการนวดและทรีตเมนต์ความงามจากผู้เชี่ยวชาญชั้นนำที่บ้าน โรงแรม หรือสปาของคุณได้อย่างง่ายดาย สัมผัสประสบการณ์กับนักบำบัดและช่างเสริมสวยที่ผ่านการรับรองตามต้องการ';
const titleEN = "Sura360 - Relax. Refresh. Rejuvenate";
const titleTH = "Sura360 - ผ่อนคลาย สดชื่น ฟื้นฟู";

// Keywords
const englishKeywords = `Spa,Massage,Sura360,Service,Wellness center,Spa services,Spa treatments,Spa near me,Spa offers,Incall,Outcall,Beauty Care,Hair Removal,Dental Clinic,Relax,Refresh,Rejuvenate
`.trim().replace(/\s\s+/g, ' ');

const thaiKeywords = `สปา,นวด,Sura360,บริการ,ศูนย์สุขภาพ,บริการสปา,ทรีตเมนต์สปา,สปาใกล้ฉัน,ข้อเสนอสปา,บริการแบบ,บริการแบบ,การดูแลความงาม,การกำจัดขน,คลินิกทันตกรรม,ผ่อนคลาย,ฟื้นฟู,ฟื้นฟูร่างกาย
`.trim().replace(/\s\s+/g, ' ');

const combinedKeywordsEN = englishKeywords;
const combinedKeywordsTH = thaiKeywords;

export const MetaTag = ({
  custom_title,
  custom_description,
}) => {
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's browser language
    const userLang = navigator.language || navigator.userLanguage;
    
    if (userLang === 'th' || userLang === 'th-TH') {
      setLanguage('th');
    } else {
      setLanguage('en');
    }
  }, []);

  // Select the meta tags based on the detected language
  const title = language === 'th' ? titleTH : titleEN;
  const description = language === 'th' ? descriptionTH : descriptionEN;
  const keywords = language === 'th' ? combinedKeywordsTH : combinedKeywordsEN;

  return (
    <Helmet>
      {/* Dynamic Title */}
      <title>{custom_title ? `${custom_title} || Sura360` : title}</title>

      {/* Meta Tags */}
      <meta name="description" content={custom_description || description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph Tags */}
      <meta property="og:url" content="https://www.sura360.com" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://www.sura360.com/logo.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://www.sura360.com/logo.png" />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <meta name="google-site-verification" content="p_RHHXpQ4vlUkLnoEZ1fTRUsRzm2kEDd0obNKWqFTcc" />
    </Helmet>
  );
};
