import { React, Locale, useEffect, useState, useDispatch, useSelector, Button, InputNumber, Select, dayjs, useLocation, useNavigate } from '../../../libraries/index.js';
import { returnObj } from './dataObj.js';
import {  getAllCategory, getAllSubCategory, handleSuccessResponse, handleErrorResponse, addServices, getServiceById, updateServices } from '../../../services';
import { fetchAccountInformation } from '../../../redux/slice/vendor/vendorSlice.js';
import { buisinesseditService, addService, bussinessServices, bussiness } from '../../../routes/routes.js';
import { MyModal } from '../../../common/index.js';
const useAddNewServiceData = (form) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = Locale();
  const [category, setCategoryList] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [error, setErr] = useState(null);
  const [showRemoveColumn, setShowRemoveColumn] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showImage, setShowImage] = useState({
    shopImages: [],
    shopImagesShow: []
  });
  // const [teamMember, setTeamMember] = useState([]);
  const [formDataService, setFormDataService] = useState();
  const [dataSource, setDataSource] = useState([
    {
      key: '1',
      start_time: 0,
      end_time: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
  ]);
  const [buissnesDay, setBuissnesDay] = useState();
  const [deleteServiceSlotDeleteIndex, setDeleteServiceSlotDeleteIndex] =useState();
  // const [temMemberArray, setTeamMemberArray] = useState([]);
  const { state } = location;
  const currentUrl = location.pathname;
  const vendorAccoutnDetails = useSelector(
    (state) => state.vendor.vendorAccoutnDetails,
  );
  const businessHours = vendorAccoutnDetails?.organization?.bussiness_hours;
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
    ],
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  useEffect(() => {
    const fetchDataAndSetSpinner = async () => {
      try {
        await fetchCategory();
        // await getAllTeamMember();
        if (currentUrl === `${bussiness}${buisinesseditService}`) {
          await getSubCategory(state.service.category?.id);
          const singleService = await getServiceById(state.service.id);
          setId(state.service.id);
          await setFormData(
            singleService.data.service,
            singleService.data.data,
          );
        }
      } catch (error) {
        handleErrorResponse(error);
        setErr(error);
      } finally {
        setSpinner(false);
      }
    };
    fetchDataAndSetSpinner();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchAccountInformation());
      } catch (error) {
        handleErrorResponse(error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (vendorAccoutnDetails.organization) buissnesDayFunction();
  }, [vendorAccoutnDetails.organization]);

  const generateTimeSlots = (startTime, endTime, intervalMinutes) => {
    const slots = [];
    let currentTime = new Date(`01/01/2024 ${startTime}`);
    const endTimeObj = new Date(`01/01/2024 ${endTime}`);

    while (currentTime < endTimeObj) {
      const hour = currentTime.getHours().toString().padStart(2, '0');
      const minute = currentTime.getMinutes().toString().padStart(2, '0');
      slots.push(`${hour}:${minute}`);
      currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
    }

    return slots;
  };

  const options = businessHours
    ? Object.values(businessHours)
      .filter((day) => day.shop_open === 'true')
      .map((day) => {
        const timeSlots = generateTimeSlots(day.open, day.close, 30);
        const uniqueSlots = Array.from(new Set(timeSlots)); // Ensure unique slots
        const minTime = uniqueSlots[0];
        const maxTime = uniqueSlots[uniqueSlots.length - 1];
        const timeDiff = 30; // 30-minute interval
        const slots = [];
        let currentTime = minTime;
        while (currentTime <= maxTime) {
          slots.push({ value: currentTime, label: currentTime });
          const [hours, minutes] = currentTime.split(':').map(Number);
          const totalMinutes = hours * 60 + minutes;
          const newTotalMinutes = totalMinutes + timeDiff;
          const newHours = Math.floor(newTotalMinutes / 60)
            .toString()
            .padStart(2, '0');
          const newMinutes = (newTotalMinutes % 60)
            .toString()
            .padStart(2, '0');
          currentTime = `${newHours}:${newMinutes}`;
        }

        return slots;
      })
      .flat()
      .filter(
        (slot, index, self) =>
          self.findIndex((s) => s.value === slot.value) === index,
      )
      .sort((a, b) => {
        // Sort in ascending order
        return (
          new Date('1970/01/01 ' + a.value) -
          new Date('1970/01/01 ' + b.value)
        );
      })
    : [];

  const columns = [
    {
      title: (t('addService.slot')),
      dataIndex: 'key',
      key: 'slot',
      render: (text, record, index) => (
        <div className="d-flex">
          <Select
            className="service-hours-select-tag"
            name="start_time"
            placeholder={`${t('header.select')}`}
            options={options}
            onChange={(value) => handleInputChange(value, 'start_time', index)}
            value={
              dataSource[index]?.start_time
                ? dataSource[index]?.start_time
                : t('header.select')
            }
          />
          <div className="p-1">To</div>
          <Select
            className="service-hours-select-tag"
            name="end_time"
            placeholder={`${t('header.select')}`}
            options={getEndOptions(index)}
            onChange={(value) => handleInputChange(value, 'end_time', index)}
            value={
              dataSource[index]?.end_time
                ? dataSource[index]?.end_time
                : t('header.select')
            }
          />
        </div>
      ),
    },
    {
      title: (t('addService.mon')),
      dataIndex: 'Monday',
      key: 'Monday',
      render: (text, record, index) => (
        <InputNumber
          min={0}
          max={100}
          value={text}
          formatter={(value) => `${value}%`}
          parser={(value) => value?.replace('%', '')}
          onChange={(value) => handleInputChange(value, 'Monday', index)}
          disabled={!buissnesDay?.monday}
        />
      ),
    },
    {
      title: (t('addService.tue')),
      dataIndex: 'Tuesday',
      key: 'Tuesday',
      render: (text, record, index) => (
        <InputNumber
          min={0}
          max={100}
          value={text}
          formatter={(value) => `${value}%`}
          parser={(value) => value?.replace('%', '')}
          onChange={(value) => handleInputChange(value, 'Tuesday', index)}
          disabled={!buissnesDay?.tuesday}
        />
      ),
    },
    {
      title: (t('addService.wed')),
      dataIndex: 'Wednesday',
      key: 'Wednesday',
      render: (text, record, index) => (
        <InputNumber
          min={0}
          max={100}
          value={text}
          formatter={(value) => `${value}%`}
          parser={(value) => value?.replace('%', '')}
          onChange={(value) => handleInputChange(value, 'Wednesday', index)}
          disabled={!buissnesDay?.wednesday}
        />
      ),
    },
    {
      title: (t('addService.thu')),
      dataIndex: 'Thursday',
      key: 'Thursday',
      render: (text, record, index) => (
        <InputNumber
          min={0}
          max={100}
          value={text}
          formatter={(value) => `${value}%`}
          parser={(value) => value?.replace('%', '')}
          onChange={(value) => handleInputChange(value, 'Thursday', index)}
          disabled={!buissnesDay?.thursday}
        />
      ),
    },
    {
      title: (t('addService.fri')),
      dataIndex: 'Friday',
      key: 'Friday',
      render: (text, record, index) => (
        <InputNumber
          min={0}
          max={100}
          value={text}
          formatter={(value) => `${value}%`}
          parser={(value) => value?.replace('%', '')}
          onChange={(value) => handleInputChange(value, 'Friday', index)}
          disabled={!buissnesDay?.friday}
        />
      ),
    },
    {
      title: (t('addService.sat')),
      dataIndex: 'Saturday',
      key: 'Saturday',
      render: (text, record, index) => (
        <InputNumber
          min={0}
          max={100}
          value={text}
          formatter={(value) => `${value}%`}
          parser={(value) => value?.replace('%', '')}
          onChange={(value) => handleInputChange(value, 'Saturday', index)}
          disabled={!buissnesDay?.saturday}
        />
      ),
    },
    {
      title: (t('addService.sun')),
      dataIndex: 'Sunday',
      key: 'Sunday',
      render: (text, record, index) => (
        <InputNumber
          min={0}
          max={100}
          value={text}
          formatter={(value) => `${value}%`}
          parser={(value) => value?.replace('%', '')}
          onChange={(value) => handleInputChange(value, 'Sunday', index)}
          disabled={!buissnesDay?.sunday}
        />
      ),
    },
    {
      title: '',
      key: 'remove',
      render: (text, record, index) => {
        if ((showRemoveColumn && index > 0) || Object.keys(text).length !== 0) {
          return (
            <>
              <Button type="danger" onClick={() => handleRemove(index)}>
                <i className="bi bi-x times-icon" aria-hidden="true"></i>
              </Button>
              <MyModal
                isComplte={true}
                showModal={showModal}
                handleClose={handleClose}
                deleteServices={deleteServices}
                message={t('ServiceField.deleteSlot')}
                deleteItemId={deleteServiceSlotDeleteIndex}
              />
            </>
          );
        } else {
          return null;
        }
      },
    },
  ].filter(Boolean);

  const getEndOptions = (startIndex) => {
    const startTime = dataSource[startIndex].start_time;
    if (!startTime) return options;

    return options.filter((option) => option.value > startTime);
  };

  const handleInputChange = (value, day, rowIndex) => {
    setDataSource((prevDataSource) => {
      const updatedDataSource = [...prevDataSource];
      updatedDataSource[rowIndex][day] = value || 0;
      return updatedDataSource;
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const deleteServices = (index) => {
    setDataSource((prevDataSource) =>
      prevDataSource.filter((_, i) => i !== index),
    );
    setShowModal(false);
  };

  const handleRemove = (index) => {
    setDeleteServiceSlotDeleteIndex(index);
    setShowModal(true);
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    const newRow = {
      start_time: 0,
      end_time: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    };

    setDataSource((prevDataSource) => [...prevDataSource, newRow]);
    setShowRemoveColumn(true);
  };

  const extractSlotValues = () => {
    const slotValues = dataSource.map(row => {
      return {
        slot_time: {
          start_time: row?.start_time || row?.start,
          end_time: row?.end_time || row?.end,
        },
        discount_days: {
          monday: row?.Monday || 0,
          tuesday: row?.Tuesday || 0,
          wednesday: row?.Wednesday || 0,
          thursday: row?.Thursday || 0,
          friday: row?.Friday || 0,
          saturday: row?.Saturday || 0,
          sunday: row?.Sunday || 0,
        }
      };
    });

    return slotValues;
  };

  const validateDescrition = (_, value) => {
    if (!value) {
      return Promise.reject(
        new Error(
          `${t('field.pleaseEnter')} ${t('ServiceField.serviceDescinEng')}`,
        ),
      );
    }
    const strippedValue = value?.replace(/(<([^>]+)>)/gi, '').trim();
    if (!strippedValue) {
      return Promise.reject(
        new Error(
          `${t('field.pleaseEnter')} ${t('ServiceField.serviceDescinEng')}`,
        ),
      );
    }
    return Promise.resolve();
  };

  const validatorImage = (_, value) => {
    if ((showImage.shopImages.length < 1) && (!formDataService)) {
      return Promise.reject(
        new Error(
          `${t("ServiceField.serviceImgValidationMsg")}`,
        ),
      );
    }
    else {

    }
    return Promise.resolve();
  }


  const getEyeClassName = () => {
    if ((showImage.shopImages.length < 1) && (!formDataService)) {
      return 'is-disable-eye-icon-service-section'
    }
    else {
      return ''
    }

  }

  const validateSpecialTerm = (_, value) => {
    if (!value) {
      return Promise.reject(
        new Error(
          `${t('field.pleaseEnter')} ${t('ServiceField.specialTermEng')}`,
        ),
      );
    }
    const strippedValue = value?.replace(/(<([^>]+)>)/gi, '').trim();
    if (!strippedValue) {
      return Promise.reject(
        new Error(
          `${t('field.pleaseEnter')} ${t('ServiceField.specialTermEng')}`,
        ),
      );
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {

    if (showImage.shopImagesShow.length > 0) {
      values.image = showImage.shopImagesShow[0];
    }
    if (currentUrl === `${bussiness}${addService}`) await addNewService(values);
    else await editService(values);
  };

  const addNewService = async (values) => {
    let slotArray = extractSlotValues();
    const obj = returnObj(
      values,
      slotArray.length > 0 ? slotArray : null,
    );
    try {
      const response = await addServices(obj);
      handleSuccessResponse(response, response.data.message);
      navigate(`${bussiness}${bussinessServices}`);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const editService = async (values) => {
    const sdate = values.psStartDate
      ? await actualDate(values.psStartDate)
      : null;
    const endate = values.psEndDate ? await actualDate(values.psEndDate) : null;
    let slotArray = extractSlotValues();
    const obj = returnObj(
      values,
      slotArray.length > 0 ? slotArray : null,
    );
    obj.id = id;
    obj.ps_start_date = sdate;
    obj.ps_end_date = endate;
    // obj.team_member_id = temMemberArray;
    try {
      const response = await updateServices(obj);
      handleSuccessResponse(response, response.data.message);
      navigate(`${bussiness}${bussinessServices}`);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const actualDate = (date) => {
    const originalDate = dayjs(date);
    const modifiedDate = originalDate
      .add(18, 'h')
      .add(4, 'm')
      .add(39, 's')
      .add(801, 'ms');
    const modifiedDateString = modifiedDate.toISOString();
    return modifiedDateString;
  };

  const getSubCategory = async (categoryId) => {
    try {
      const response = await getAllSubCategory(categoryId);
      setSubCategory(response.data);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setShowImage(file);
  }

  const fetchCategory = async () => {
    const categoryResponse = await getAllCategory();
    setCategoryList(categoryResponse.data);
  };

  const getAllTeamMember = async () => {
    // const params = { page_number: 1, per_page: 15 };
    // const response = await viewAllTeamMember(params);
    // // await setTeamMember(response.data.data);
  };

  const updateDataSource = (apiData) => {
    if (!apiData || !apiData.slots) {
      return;
    }

    const updatedDataSource = apiData.slots.map((slot) => {
      return {
        start_time: slot?.slot_time?.start_time,
        end_time: slot?.slot_time?.end_time,
        Monday:
          slot.discount_days && slot.discount_days.monday !== undefined
            ? slot.discount_days.monday
            : 0,
        Tuesday:
          slot.discount_days && slot.discount_days.tuesday !== undefined
            ? slot.discount_days.tuesday
            : 0,
        Wednesday:
          slot.discount_days && slot.discount_days.wednesday !== undefined
            ? slot.discount_days.wednesday
            : 0,
        Thursday:
          slot.discount_days && slot.discount_days.thursday !== undefined
            ? slot.discount_days.thursday
            : 0,
        Friday:
          slot.discount_days && slot.discount_days.friday !== undefined
            ? slot.discount_days.friday
            : 0,
        Saturday:
          slot.discount_days && slot.discount_days.saturday !== undefined
            ? slot.discount_days.saturday
            : 0,
        Sunday:
          slot.discount_days && slot.discount_days.sunday !== undefined
            ? slot.discount_days.sunday
            : 0,
      };
    });
    setDataSource(updatedDataSource);
  };

  const setFormData = async (state, data) => {
    const selectedTeamMember = data.map((item) => {
      return item.member_info.id;
    });
    await updateDataSource(state.discount_slot);
    setId(state?.id);
    const formData = {
      categoryId: state?.category?.id,
      subCategoryId: state?.sub_category?.id,
      psStartDate: state?.ps_start_date
        ? dayjs(state.ps_start_date, 'YYYY-MM-DD')
        : null,
      psEndDate: state?.ps_end_date
        ? dayjs(state.ps_end_date, 'YYYY-MM-DD')
        : null,
      duration: state?.duration,
      serviceNameEnglish: state?.service_name_en,
      serviceNameThai: state?.service_name_thai,
      price: state?.price,
      reschedule: state?.reschedule_day,
      cancelDay: state?.cancel_day,
      specialPrice: state?.special_price,
      displayPosition: state?.display_position,
      descriptionEnglish: state?.description_en,
      descriptionThailand: state?.description_thai,
      specialTermEnglish: state?.special_term_en,
      specialTermThailand: state?.special_term_thai,
      image: state?.service_image,
      vouturs: ['Voucher', 'Featured Service', 'Installment'].filter(
        (value) => state[`is_${value.toLowerCase().replace(' ', '_')}`],
      ),
      avialableTeamMembers: selectedTeamMember,
    };
    setFormDataService(state.service_image)
    form.setFieldsValue(formData);
  };

  const validateSpecialPrice = (_, value) => {
    const priceValue = parseInt(form.getFieldValue('price'), 10);
    const specialPriceValue = parseInt(value, 10);
    if (
      !isNaN(priceValue) &&
      !isNaN(specialPriceValue) &&
      specialPriceValue >= priceValue
    )
      return Promise.reject('Must be less than Price');
    return Promise.resolve();
  };

  const buissnesDayFunction = () => {
    let newBuissnessDay = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    if (vendorAccoutnDetails.organization.bussiness_hours) {
      let bussiness_hours = vendorAccoutnDetails.organization.bussiness_hours;
      setBuissnesDay((prevState) => ({
        ...prevState,
        monday:
          bussiness_hours.monday && bussiness_hours.monday.shop_open === 'true'
            ? true
            : false,
        tuesday:
          bussiness_hours.tuesday &&
            bussiness_hours.tuesday.shop_open === 'true'
            ? true
            : false,
        wednesday:
          bussiness_hours.wednesday &&
            bussiness_hours.wednesday.shop_open === 'true'
            ? true
            : false,
        thursday:
          bussiness_hours.thursday &&
            bussiness_hours.thursday.shop_open === 'true'
            ? true
            : false,
        friday:
          bussiness_hours.friday && bussiness_hours.friday.shop_open === 'true'
            ? true
            : false,
        saturday:
          bussiness_hours.saturday &&
            bussiness_hours.saturday.shop_open === 'true'
            ? true
            : false,
        sunday:
          bussiness_hours.sunday && bussiness_hours.sunday.shop_open === 'true'
            ? true
            : false,
      }));
    } else {
      setBuissnesDay(newBuissnessDay);
    }
  };

  // const handleTeamMemberCheck = (e) => {
  //   let tempArray = [...temMemberArray];
  //   const value = e.target.value;
  //   const valueIndex = tempArray.indexOf(value);
  //   if (valueIndex > -1) {
  //     tempArray.splice(valueIndex, 1);
  //   } else {
  //     tempArray.push(value);
  //   }
  //   setTeamMemberArray(tempArray);
  // };

  return {
    setSpinner,
    currentUrl,
    error,
    state,
    spinner,
    validateSpecialPrice,
    dataSource,
    getSubCategory,
    category,
    subCategory,
    columns,
    handleAddRow,
    onFinish,
    // teamMember,
    // handleTeamMemberCheck,
    // temMemberArray,
    modules,
    formats,
    validateDescrition,
    validateSpecialTerm,
    showImage,
    setShowImage,
    formDataService,
    validatorImage,
    getEyeClassName
  };
};

export default useAddNewServiceData;
