import './AddToFavouriteSettingHeader.css';
import { Locale, React } from '../../libraries/index';
import { favouriteShop, favouriteService, user } from '../../routes/routes';
const AddToFavouriteSettingHeader = ({ isPage, navigateFun }) => {
  const { t } = Locale();
  return (
    <>
      <div className="user-booking-tab-outer-div">
        <span
          onClick={(e) => {
            navigateFun(e, `${user}${favouriteShop}`);
          }}
          className={`user-bookings-tab btn ms-1 ${isPage.accountEditPage ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'}`}
        >
          {t('favouritePage.shop')}
        </span>
        <span
          onClick={(e) => {
            navigateFun(e, `${user}${favouriteService}`);
          }}
          className={`user-bookings-tab btn ms-1 ${isPage.openingHours ? 'user-bookings-tab-active' : 'user-bookings-tab-de-active'}`}
        >
          {t('favouritePage.service')}
        </span>
      </div>
    </>
  );
};
export default AddToFavouriteSettingHeader;