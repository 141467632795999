import { React, Locale, useNavigate, GoogleLogin } from '../../libraries';
import { handleErrorResponse, handleSuccessResponse, googleSignIn, showUserDetails, showCartList, organizationStatus } from '../../services';
import { Profile, bussiness, dashboard, home } from '../../routes/routes';
const REACT_APP_GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;

const Google = ({ className, msg }) => {
  const navigate = useNavigate();
  const { t } = Locale();
  
  const responseGoogle = async (res) => {
    try {
      const response = await googleSignIn({ token: res.credential });
      if (response && response.data.access_token) {
        (response?.data?.initial_user) && localStorage.setItem('firstCashback', true)
        const responseUserDetails = await showUserDetails();
        if (response?.data?.role === 'Freelancer') {
          localStorage.setItem('type', true);
          localStorage.setItem('role', 'Vendor');
        }
        else {
          localStorage.setItem('role', response.data.role);
        }
        if (response.data.role === 'Customer') {
          customerSignIn(responseUserDetails);
        }
        else if (response.data.role === 'Vendor') {
          vendorSignIn(responseUserDetails);
        }
        else if (response.data.role === 'TeamMember') {
          teamMemberSignIn(responseUserDetails)
        }
      }
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const customerSignIn = async (response) => {
    const cartResponse = await showCartList();
    let cartCount = cartResponse?.data?.data?.length;
    localStorage.setItem('status', response.data.user.status);
    if (cartCount === null || isNaN(cartCount) || cartCount === undefined) {
      cartCount = 0;
    }

    localStorage.setItem('cartCount', cartCount);
    let name = response.data.user.first_name;
    let lastName = response.data.user.last_name
      ? response.data.user.last_name
      : '';
    let fullName = name + ' ' + lastName;
    localStorage.setItem('name', fullName);
    localStorage.setItem('userId', response.data.user.id);
    localStorage.setItem('profilePhoto', response.data.image_url ? response.data.image_url : '');
    handleSuccessResponse(response, response.data.message);
    navigate(home);
  }

  const vendorSignIn = async (response) => {
    const getVerification = await organizationStatus();
    if (getVerification.data.status === 'verified') {
      localStorage.setItem('status', 'verified');
    } else if (
      getVerification.data.account &&
      getVerification.data.finance
    ) {
      localStorage.setItem('status', 'underReview');
    } else {
      localStorage.setItem('status', 'completProfile');
    }
    localStorage.setItem('userId', response.data.user.id);
    localStorage.setItem('orgId', response.data.organization_id);
    if (response.data.image_url) {
      localStorage.setItem(
        'profilePhoto',
        response.data.image_url,
      );
    }
    localStorage.setItem('isSection', 'isDashboard');
    localStorage.setItem(
      'name',
      response.data.user.first_name +
      ' ' +
      response.data.user.last_name,
    );
    handleSuccessResponse(response, response.data.message);
    window.history.pushState(null, null, `${bussiness}${dashboard}`);
    navigate(`${bussiness}${dashboard}`);
    const disableBack = (event) => {
      window.history.pushState(null, null, `${bussiness}${dashboard}`);
      event.preventDefault();
    };
    window.addEventListener('popstate', disableBack);
    return () => window.removeEventListener('popstate', disableBack);
  }

  const teamMemberSignIn = async (response) => {
    const getShowResponse = await showUserDetails();
    localStorage.setItem('status', 'verified');
    localStorage.setItem('userId', response?.data?.user?.id);
    if (getShowResponse.data.image_url) {
      localStorage.setItem(
        'profilePhoto',
        getShowResponse.data.image_url,
      );
    }
    localStorage.setItem('isSection', 'isVendorProfile');

    localStorage.setItem(
      'name',
      response?.data?.user?.first_name +
      ' ' +
      response?.data?.user?.last_name,
    );
    handleSuccessResponse(response, response?.data?.message);
    window.history.pushState(null, null, `${bussiness}${Profile}`);
    navigate(`${bussiness}${Profile}`);
    const disableBack = (event) => {
      window.history.pushState(null, null, `${bussiness}${Profile}`);
      event.preventDefault();
    };
    window.addEventListener('popstate', disableBack);
    return () => window.removeEventListener('popstate', disableBack);
  }

  return (
    <div className={className}>
      <GoogleLogin
        theme="filled_blue"
        size="medium"
        text={t('signMessage.signWithgoogle')}
        onSuccess={responseGoogle}
        cookiePolicy={'single_host_origin'}
        locale={msg}
        clientId={REACT_APP_GOOGLE_ID}
      />
    </div>
  );
};
export default Google;