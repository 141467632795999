import { React, PropTypes, Navigate } from '../libraries';
import { home, signIn } from '../routes/routes.js';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('access_token');
  const role = localStorage.getItem('role');
  const isServer = localStorage.getItem("isServer");

  if (isServer === "off") {
    return <Navigate to={home} />;
  }
  else if (token) {
    return children;
  }
  else {
    return <Navigate to={role ? signIn : home} />;
  }
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
