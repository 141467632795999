import "./blog.css";
import { React, Link, useState, useEffect, Locale, useLocation, useNavigate } from "../../libraries";
import { CustomSpinner, EmptyComponent, Footer } from "../../common";
import { NavbarLogic } from "../../components";
import { MetaTag } from "../../customHooks/metaKeywords";
import { BreadCrumb } from "../BreadCrumb/BreadCrumb.js";
import { getBlog, handleErrorResponse } from "../../services";
import { DefaultServiceImg } from "../../assets/Images.js";
import { getFormatedDateMonthName } from "../../customHooks/dateTimeFormat.js";
import { encryptData } from "../../customHooks/urlEncyption.js";
import { blogDetails } from "../../routes/routes.js";

const Blog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = Locale();
  const [blogList, setBlogList] = useState();
  const [loader, setLoader] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const language = localStorage.getItem("i18nextLng");
  const isThaiLanguage = language === 'thai';
  const BradCrumbData = [
    {
      breadCrumbName: t("field.blog"),
      breadCrumbPath: "/",
    },
  ];
  const handleLinkClick = (blog) => {
    const encryptedId = encryptData(blog?.id)
    navigate(`${blogDetails}/${encryptedId}`)
    window.scrollTo(0, 0);
  };
  const getBlogList = async () => {
    try {
      const response = await getBlog();
      if (response?.data?.blogs?.length) {
        setBlogList(response.data.blogs);
      } else {
        setIsEmpty(true);
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getBlogList();
  }, []);
  const removeHtmlTags = (input) => {
    if (!input) return ""; // Return an empty string if input is falsy

    // Remove HTML tags using DOMParser
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.body.textContent || "";
  };


  useEffect(() => {
    if (localStorage.getItem('isSection')) {
      localStorage.removeItem('isSection');
    }
  }, [location.pathname])

  const getBlogTitle = (blog) => {
    return isThaiLanguage ? blog?.title_thai ?? blog?.title_en : blog?.title_en;
  }

  const getBlogDesc = (blog) => {
    const isDesc = isThaiLanguage ? blog?.description_thai ?? blog?.description_en : blog?.description_en;
    return removeHtmlTags(isDesc)
  }

  return (
    <>
      <MetaTag
        custom_title={blogList?.[0]?.title}
        custom_description={blogList?.[0].description}
      />

      <NavbarLogic />
      <div className="container about-us-outer-container mb-3">
        <BreadCrumb data={BradCrumbData} />
        {((!loader) && (!isEmpty)) && (
          <>
            <div className="row">
              <div className="col-lg-12 col-ms-12">
                <div className="row">
                  {blogList?.map((blog, index) => (
                    <div className="col-lg-4 col-ms-4 mt-2" key={index}>
                      <div className="card shadow-sm blog-card">
                        {(blog?.blog_image) ? (
                          <img
                            src={blog.blog_image}
                            alt={blog.title}
                            style={{ height: "300px" }}
                          />
                        ) : (<img
                          src={DefaultServiceImg}
                          alt={blog.title}
                          style={{ height: "300px" }}
                        />)}
                        <div className="text-danger mt-2 ps-2 blog-date">
                          {getFormatedDateMonthName(blog.blog_date)}
                        </div>
                        <div className="manage-blog mt-1">
                          <div className="ps-2 blog-title">
                            {getBlogTitle(blog)}
                          </div>
                          <div className="ps-2 mt-1 blog-description">
                            <p>
                              {getBlogDesc(blog)}

                            </p>
                          </div>
                        </div>
                        <p className="text-end pe-2 continue-button">
                          <div
                            className="continue-link text-danger"
                            onClick={() => handleLinkClick(blog)}
                          >
                            {t('field.continueReading')}
                            {" "}
                            <i className="bi bi-arrow-right"></i>
                          </div>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        {(loader) && (
          <>
            <CustomSpinner />
          </>
        )}
        {(isEmpty) && (
          <>
            <div>
              <EmptyComponent type={"isBlog"} />
            </div>
          </>
        )}
      </div>
      <div className={`${(loader) ? 'loader-blog-section' : ''}`}>
        <Footer />
      </div>
    </>
  );
};

export default Blog;
