import {  Route, Routes, PropTypes, React, useLocation, useEffect, useState } from '../libraries';
import ProtectedRoute from '../routes/protectedRoute.js';
import { Sidebar } from './component';
import { PageNotFound } from '../common/index.js';
import { bussiness } from '../routes/routes.js';
import { teamMemberRoutes, vendorRoutes } from '../routes/index.js'
import { ErrorHome, ErrorHomeIpad, ErrorHomeMobile } from '../assets/Images';
const Vendor = () => {
  const role = localStorage.getItem('role');
  const location = useLocation();
  const getErrorImage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) return ErrorHome;
    if (screenWidth > 420) return ErrorHomeIpad;
    return ErrorHomeMobile;
  };

  const [imageUrl, setImageUrl] = useState(getErrorImage());

  // Determine the routes based on the user's role
  const routes = role === 'TeamMember' ? teamMemberRoutes : vendorRoutes;
  const isServerOff=localStorage.getItem("isServer")==='off'
  // Check if the current path matches any of the routes
  const isBusinessRoute = routes.some(route => location.pathname.includes(`${bussiness}${route.path}`)) || location.pathname.includes('bussiness/payout/summary/');

  // Function to render routes
  const renderRoutes = (routes) =>
    routes.map(({ path, Component }) => (
      <Route key={path} path={path} element={<Component />} />
    ));
    useEffect(() => {
      const handleResize = () => setImageUrl(getErrorImage());
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (<>
  {(isServerOff)?<>
    <img className="home-errorpage-vendor" src={imageUrl} alt="Error" />
  </>:<>
    <ProtectedRoute>
      {isBusinessRoute ? (
        <Sidebar>
          <Routes>
            {renderRoutes(routes)}
          </Routes>
        </Sidebar>
      ) : (
        <Routes>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </ProtectedRoute>
  
  
  </>}
  </>
  );
};

Vendor.propTypes = {
  pageViews: PropTypes.number,
};

export default Vendor;
