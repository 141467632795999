import { useState } from '../../../libraries';
import { serviceCreate } from '../../../services/reviewRatings';
import { handleErrorResponse, handleSuccessResponse } from '../../../services';

const ServiceModalLogic = (setUpdateReviewRating) => {
  const [filled1, setFilled1] = useState(false);
  const [filled2, setFilled2] = useState(false);
  const [filled3, setFilled3] = useState(false);
  const [filled4, setFilled4] = useState(false);
  const [filled5, setFilled5] = useState(false);
  const [ratingValidation, setRatingValidation] = useState('');
  const [totalStarsClicked, setTotalStarsClicked] = useState(0);

  const handleClick = (clickedIndex) => {
    const clickedStars = clickedIndex;
    setTotalStarsClicked(clickedStars);
  };

  const handleSubmit = async ({ serviceId, closeButtonElement }) => {
    if (totalStarsClicked === 0) {
      setRatingValidation('Please rate all categories');
      return;
    }
    if (closeButtonElement) {
      const closeButtonObject = {
        element: closeButtonElement,
        click: function () {
          closeButtonElement.click();
          setTotalStarsClicked(0);
        },
      };
      closeButtonObject.click();
      setTotalStarsClicked(0);
    }

    try {
      const response = await serviceCreate({
        reviewable_id: serviceId,
        reviewable_type: 'service',
        service_rating: totalStarsClicked,
      });
      setUpdateReviewRating((prev) => prev + 1);
      await handleSuccessResponse(response, response.data.message);
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  return {
    handleSubmit,
    handleClick,
    filled1,
    filled2,
    filled3,
    filled4,
    filled5,
    totalStarsClicked,
    setFilled1,
    setFilled2,
    setFilled3,
    setFilled4,
    setFilled5,
    ratingValidation,
    setRatingValidation
  };
};
export default ServiceModalLogic;