import './App.css';
import { React, useEffect, Suspense, useMemo, ToastContainer, Route, Routes, useDispatch } from './libraries';
import { BrowserRouter as Router } from 'react-router-dom';
import { CustomSpinner } from './common';
import { fetchUserFooterInformation } from './redux/slice/user/footerSlice.js';
import { Helmet } from 'react-helmet';
import { appRoutes } from './routes/index.js';
import { useUserRoleAndSection } from './customHooks/customHooks.js';
import { useGeolocation } from './customHooks/locationHooks.js';
import HomeDataProvider from './contextApi/home/homeContextApi.js';
import PaymentDone from './pages/PaymentComplete/PaymentDone.js';
import PaymentFailed from './pages/PaymentComplete/PaymentFailed.js';
import ScrollToTop from "react-scroll-to-top";
import PageNotFound from './common/pageNotFound/pageNotFound.js';
import { combinedKeywords, MetaTag } from './customHooks/metaKeywords.js';

const App = () => {
  const dispatch = useDispatch();
  const { role, isSection } = useUserRoleAndSection();

  useEffect(() => {
    if ((role === 'Customer' && !isSection) || !role) {
      dispatch(fetchUserFooterInformation());
    }
  }, [dispatch, role, isSection]);

  useGeolocation();

  const memoizedRoutes = useMemo(() => appRoutes.map(({ path, element, exact = false }) => (
    <>
      <Route
        index={path}
        key={path}
        exact={exact}
        path={path}
        element={
          <Suspense fallback={<CustomSpinner />}>
            {React.createElement(element)}
          </Suspense>
        }
      />
      <Route path={'/payment-done'} element={<PaymentDone />} />
      <Route path='/payment-fail' element={<PaymentFailed />} />
      <Route path="*" element={<PageNotFound />} />
    </>
  )), []);

  return (
    <>
      <Helmet>
        <link rel="icon" href="/images.png" />
      </Helmet>
      <MetaTag />
      <HomeDataProvider>
        <Router>
          <Routes>
            {memoizedRoutes}
          </Routes>
          <ScrollToTop smooth color="white" height="50%" />
        </Router>
      </HomeDataProvider>
      <ToastContainer />
    </>
  );
};

export default App;
